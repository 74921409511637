import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/MdxPage.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Version 1, updated September 2021.`}</p>
    <h2>{`Introduction`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`These Training Terms are a legal agreement between you (the "Customer" or "you") and Spacecat Ltd (the "Provider", "we" or "us"), a company registered in England and Wales with company number 13166582, whose registered office is c/o Ozkan Accountants Ltd, 2nd Floor, Suite 12, Vantage Point, New England Road, Brighton, England BN1 4GW, for your purchase of training courses and training materials ("Training Courses" and "Training Materials" respectively), which includes printed materials and online videos and documentation.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`By clicking on the "purchase" button on your event page, you agree to these terms which will bind you and (if you are an employer) your employees. If you do not agree to these terms, we shall not sell Training Materials to you and you must discontinue the purchasing process now.`}</p>
      </li>
    </ol>
    <h2>{`Application`}</h2>
    <ol>
      <li parentName="ol">{`These terms and conditions (the Terms and Conditions) shall apply to the provision of the Training by the Provider to the Customer.`}</li>
    </ol>
    <h2>{`Definitions and Interpretation`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In these Training Terms, unless the context otherwise requires, the following expressions shall have the following meanings:`}</p>
        <p parentName="li"><strong parentName="p">{`Agreement:`}</strong>{` these Terms and Conditions and (i) the signed Contract for Services; or (ii) completed Online Booking Process.`}</p>
        <p parentName="li"><strong parentName="p">{`Business Day:`}</strong>{` a day other than a Saturday, Sunday or public holiday in England, when banks in London are open for business.`}</p>
        <p parentName="li"><strong parentName="p">{`Charges:`}</strong>{` the charges payable by the Customer for the Training in accordance with clause 7 (Charges and payment).`}</p>
        <p parentName="li"><strong parentName="p">{`Contract for Services:`}</strong>{` The document sent by the Provider to the Customer, following an indication by Customer that it wishes to obtain training services from the Provider, setting out the details of the Training to be provided and the basis upon which the Provider proposes to provide them.`}</p>
        <p parentName="li"><strong parentName="p">{`Delegate(s):`}</strong>{` an individual or representative scheduled by the Customer to attend the Training.`}</p>
        <p parentName="li"><strong parentName="p">{`Customer:`}</strong>{` the person or firm who purchases the Training from the Provider.`}</p>
        <p parentName="li"><strong parentName="p">{`Customer Data:`}</strong>{` the data provided by the Customer for the purpose of facilitating the Training.`}</p>
        <p parentName="li"><strong parentName="p">{`Data Protection Legislation:`}</strong>{` means:`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`The General Data Protection Regulation (GDPR), the Data Protection Act 2018, the Privacy and Electronic Communications (EC Directive) Regulations 2003; and`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`any other legislation in force from time to time relating to privacy and/or the Processing of Personal Data and applicable to the provision and receipt of Training under these Terms and Conditions; and any statutory codes of practice issued by the Information Commissioner in relation to such legislation.`}</p>
          </li>
        </ol>
        <p parentName="li"><strong parentName="p">{`In-House:`}</strong>{` Training provided by the Provider for a particular Customer, at their premises or online, exclusively for their Delegates.`}</p>
        <p parentName="li"><strong parentName="p">{`Online Booking Process:`}</strong>{` The booking process available through the following websites or their links: `}<a parentName="p" {...{
            "href": "https://spacecat.io"
          }}>{`https://spacecat.io`}</a>{`.`}</p>
        <p parentName="li"><strong parentName="p">{`Personal Data:`}</strong>{` has the meaning given to it in the Data Protection Legislation.`}</p>
        <p parentName="li"><strong parentName="p">{`Processing:`}</strong>{` has the meaning given to it in the Data Protection Legislation.`}</p>
        <p parentName="li"><strong parentName="p">{`Provider:`}</strong>{` Spacecat Ltd, whose company information and contact details appear at the top of this document.`}</p>
        <p parentName="li"><strong parentName="p">{`Public:`}</strong>{` Training provided by the Provider, at a physical location or online, for Delegates who may be from multiple Customers.`}</p>
        <p parentName="li"><strong parentName="p">{`Training:`}</strong>{` the training, either In-house or Public, to be supplied by the Provider to the Customer as described in the Contract for Services or as part of the Online Booking Process.`}</p>
        <p parentName="li"><strong parentName="p">{`Training Materials:`}</strong>{` any materials or documents provided by the Provider as part of the Training.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`A reference to a statute or statutory provision is a reference to it as amended or re-enacted. A reference to a statute or statutory provision includes all subordinate legislation made under that statute or statutory provision.`}</p>
      </li>
    </ol>
    <h2>{`Basis of these Terms`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`These Training Terms shall come into effect when either:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`Customer completes the Online Booking Process; or`}</li>
          <li parentName="ol">{`Upon receipt by the Provider of an electronic or hard copy of the ontract for Services signed by the Customer, at which point these Terms and onditions shall be deemed incorporated into the Contract for Services.`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Save for terms pertaining to the relevant Training in the Online Booking Process or the Contract for Services, any descriptive matter or advertising issued by the Provider, and any descriptions contained in the Provider's catalogues, brochures or on their website, are issued or published for the sole purpose of giving an approximate idea of the Training described in them; They shall not form part of these Terms and Conditions nor have any contractual force.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`These Terms and Conditions apply to the exclusion of any other terms that the Customer seeks to impose or incorporate, or which are implied by trade, custom, practice or course of dealing.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Any Contract for Services is only valid for acceptance for a period of 30 calendar days from its date of issue.`}</p>
      </li>
    </ol>
    <h2>{`Supply of the Training`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The Provider shall use reasonable endeavours to supply the Training to the Customer in accordance with these Terms and Conditions in all material respects but reserves the right to change the course content of any Training Course at any time and without notice.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Provider shall use reasonable endeavours to meet any specified training dates, but any such dates shall be anticipated dates only and may be subject to alteration.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Provider reserves the right to amend the Agreement if necessary to comply with any applicable law or regulatory requirement, or if the amendment will not materially affect the nature or quality of the
Training, and the Provider shall notify the Customer in any such event.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Notwithstanding the above sub-clauses, the Provider reserves the right to cancel Training at any time, without incurring additional liability to the Customer or any Delegates. In such circumstances, the Provider will offer (at its sole discretion) alternative dates, a full refund, or a credit note.`}</p>
      </li>
    </ol>
    <h2>{`Customer's Obligations`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The Customer shall:`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`co-operate with the Provider in all matters relating to the Training;`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`provide the Provider, its employees, agents, consultants and subcontractors, with any information which may reasonably be required by the Provider in the organisation of the Training, including, but not limited to, details in respect of the Delegate(s) and ensure that such information is complete and accurate in all material respects; and`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`where Training is being delivered at its premises, provide the Provider with (i) access, training space and any equipment necessary for the delivery of the Training; and (ii) such facilities as are reasonably notified to the Customer in advance.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Customer agrees not to, under any circumstances, record, reproduce, or distribute any part of the Training without obtaining prior written consent from the Provider. This includes, but is not limited to, audio, video, or any other form of recording, whether for personal or commercial purposes.`}</p>
      </li>
    </ol>
    <h2>{`Charges and Payment`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Unless otherwise stated in the Contract for Services, the Charges for the Training shall be calculated on a per session per Delegate basis.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Customer shall pay any invoice submitted by the Provider within 30 calendar days of the date of the invoice, and in any event prior to the Training taking place, to a bank account provided on the invoice by the Provider, or in the case of online bookings, shall make payment as required by the Online Booking Process.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Failure by the Customer to pay any Charges when they fall due may (at the Providers discretion) result in:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`the Delegates' place on the Training being withdrawn;`}</li>
          <li parentName="ol">{`the Provider ceasing to provide the Training; and/or`}</li>
          <li parentName="ol">{`the Provider withholding any certification due to the Delegates from the Training.`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Without prejudice to any other right or remedy that it may have, if the Customer fails to pay the Provider any sum due under this Agreement on the due date:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`the Customer shall pay interest on the overdue sum from the due date until payment of the overdue sum, whether before or after judgment. Interest under this clause will accrue each day at 4% a year above the Bank of England’s base rate from time to time.`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`All sums payable to the Provider under this agreement:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`are exclusive of VAT, and the Customer shall in addition pay an amount equal to any VAT chargeable on those sums on delivery of a VAT invoice; and`}</li>
          <li parentName="ol">{`shall be paid in full without any set-off, counterclaim, deduction or withholding (other than any deduction or withholding of tax as required by law).`}</li>
        </ol>
      </li>
    </ol>
    <h2>{`Cancellation`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The Customer may cancel Public Training on 30 calendar days’ notice to the Provider. Cancellations must be provided by email to the Provider at `}<a parentName="p" {...{
            "href": "mailto:hello@spacecat.io."
          }}>{`hello@spacecat.io.`}</a>{` Public Training may not be cancelled or rescheduled within 30 days of the start date of the Public Training.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Customer may cancel In-House Training on 45 calendar days’ notice to the Provider. Cancellations must be provided by email to the Provider at `}<a parentName="p" {...{
            "href": "mailto:hello@spacecat.io."
          }}>{`hello@spacecat.io.`}</a>{` In-House Training may not be cancelled or rescheduled within 45 days of the start date of the In-House Training.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Non-classroom based Training may not be cancelled or rescheduled under any circumstances and Charges for non-classroom based Training are therefore non-refundable.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Training may only be cancelled by the Customer in accordance with this clause 8 (Cancellation). If a Customer or Delegates fail to attend all or part of any Training, full payment of the Charges shall be required.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If a refund is approved by the Provider, it will be made through the original mode of payment only.`}</p>
      </li>
    </ol>
    <h2>{`Intellectual Property Rights`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`All intellectual property rights in or arising out of or in connection with the Training, including any associated Training Materials shall be owned by the Provider.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`No reproductions, scans or copies (wholly or in part) shall be made of the Training Material without the prior written consent of the Provider.`}</p>
      </li>
    </ol>
    <h2>{`Customer Data`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`As between the parties, the Customer shall own all right, title and interest in and to all of the Customer Data.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Customer grants the Provider an irrevocable, unlimited and royalty-free licence to use the Customer Data provided to the Provider for the purposes of providing the Training.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Each party warrants that for the purposes of this Agreement it:`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`shall comply with the provisions of the Data Protection Legislation, including without limitation that it:`}</p>
            <p parentName="li">{`(a) shall use Personal Data in accordance with the permissions or consents obtained from the data subjects (as defined in the Data Protection Legislation) or otherwise in accordance with the Data Protection Legislation;`}</p>
            <p parentName="li">{`(b) shall communicate to the other party the terms of any permissions or consents obtained from the data subjects;`}</p>
            <p parentName="li">{`(c) shall have in place appropriate technical and organisational security measures against unauthorised or unlawful Processing of Personal Data and against accidental loss or destruction of, or damage to, Personal Data and shall take all reasonable steps to ensure the reliability of its personnel who have access to such Personal Data and to impose obligations of confidentiality upon such personnel and to ensure that such personnel are aware of their responsibilities under the Data Protection Legislation;`}</p>
            <p parentName="li">{`(d) shall not transfer Personal Data outside the European Economic Area save in accordance with the Data Protection Legislation;`}</p>
            <p parentName="li">{`(e) shall comply with any request or notice it receives from a data subject in its capacity as a data controller;`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`shall upon request provide such assistance as is reasonably necessary to the other party to enable that party to comply with its obligations as a data controller (as defined in the Data Protection Legislation);`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`shall inform the other party as soon as reasonably practicable of the discovery of any actual or suspected data-breach relating to the Processing of Personal Data in connection with this Agreement;`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`shall, except to the extent prohibited by applicable law, inform the other party upon receipt of a complaint from a data subject or if approached by any regulatory body in connection with its compliance with the Data Protection Legislation in connection with this Agreement;`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`shall, except to the extent prohibited by applicable law, consult the other party in good faith as to the timing, manner and content of any response to a complaint from a data subject or approach by any Regulatory Body in connection with compliance with the Data Protection Legislation in connection with the Agreement.`}</p>
          </li>
        </ol>
      </li>
    </ol>
    <h2>{`Limitation of Liability`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Nothing in these Terms and Conditions limits any liability which cannot legally be limited, including, but not limited to, liability for:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`death or personal injury caused by negligence;`}</li>
          <li parentName="ol">{`fraud or fraudulent misrepresentation; and`}</li>
          <li parentName="ol">{`breach of the terms implied by section 2 of the Supply of Goods and Services Act 1982 (title and quiet possession).`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Subject to clause 11.1 (immediately above):`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`The Provider shall not be liable whether in tort (including for negligence or breach of statutory duty), contract, misrepresentation, restitution or otherwise for any loss of profits, loss of business, loss of income, depletion of goodwill and/or similar losses or loss or corruption of data or information, or pure economic loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses however arising;`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Provider's total aggregate liability in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with the Agreement shall be limited to the total Charges paid for the Training.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The terms implied by sections 13 to 15 of the Sale of Goods Act 1979 and sections 3 and 4 of the Supply of Goods and Services Act 1982 are, to the fullest extent permitted by law, excluded from these Terms and Conditions.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`This clause 11 shall survive termination of the Agreement.`}</p>
      </li>
    </ol>
    <h2>{`Confidentiality`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Each party may be given access to confidential information from the other party in order to perform its obligations under the Agreement. A party's confidential information shall not be deemed to include information that:`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`is or becomes publicly known other than through any act or omission of the receiving party;`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`was in the other party's lawful possession before the disclosure;`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`is lawfully disclosed to the receiving party by a third party without restriction on disclosure; or`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`is independently developed by the other party, which independent development can be shown by written evidence.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Subject to clause 12.3 (immediately below), each party shall hold the other's confidential information in confidence and not make the other's confidential information available to any third party, or use the other's confidential information for any purpose other than the implementation of the Agreement.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`A party may disclose confidential information to the extent such confidential information is required to be disclosed by law, by any governmental or other regulatory authority or by a court or other authority of competent jurisdiction, provided that, to the extent it is legally permitted to do so, it gives the other party as much notice of such disclosure as possible and, where notice of disclosure is not prohibited and is given in accordance with this clause 12.3, it takes into account the reasonable requests of the other party in relation to the content of such disclosure.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Customer acknowledges that its information may be used by the Provider on an anonymous basis without limitation including compiling and publishing reports.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The above provisions of this clause 12 (Confidentiality) shall survive termination of the Agreement, however arising.`}</p>
      </li>
    </ol>
    <h2>{`Termination`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Without affecting any other right or remedy available to it, either party to the Agreement may terminate it with immediate effect by giving written notice to the other party if:`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`the other party commits a material breach of any term of the Agreement which breach is irremediable or (if such breach is remediable) fails to remedy that breach within a period of 30 calendar days after being notified in writing to do so;`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`the other party takes any step or action in connection with its entering administration, provisional liquidation, bankruptcy or any composition or arrangement with its creditors (other than in relation to a solvent restructuring), being wound up (whether voluntarily or by order of the court, unless for the purpose of a solvent restructuring), having a receiver appointed to any of its assets or ceasing to carry on business or, if the step or action is taken in another jurisdiction, in connection with any analogous procedure in the relevant jurisdiction;`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`the other party suspends, or threatens to suspend, or ceases or threatens to cease to carry on all or a substantial part of its business; or`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`the other party's financial position deteriorates to such an extent that in the terminating party's opinion the other party's capability to adequately fulfil its obligations under the Agreement has been placed in jeopardy.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Without affecting any other right or remedy available to it, the Provider may terminate the agreement with immediate effect by giving written notice to the Customer if the Customer fails to pay any amount due under the Agreement on the due date for payment.`}</p>
      </li>
    </ol>
    <h2>{`Consequences of Termination`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`On termination of the Agreement:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`the Customer shall return any of the Training Materials which have not been fully paid for; and`}</li>
          <li parentName="ol">{`any provision of the Agreement that expressly or by implication is intended to come into or continue in force on or after termination of the Agreement shall remain in full force and effect.`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Termination of the Agreement shall not affect any of the rights, remedies, obligations or liabilities of the parties that have accrued up to the date of termination, including the right to claim damages in respect of any breach of the Agreement which existed at or before the date of termination.`}</p>
      </li>
    </ol>
    <h2>{`Force Majeur`}</h2>
    <p>{`The Provider shall have no liability to the Customer under the Agreement if it is prevented from or delayed in performing its obligations under the Agreement, or from carrying on its business, by acts, events, omissions or accidents beyond its reasonable control, including, without limitation, strikes, lock-outs or other industrial disputes (whether involving the workforce of the Provider or any other party), failure of a utility service or transport or telecommunications network, act of God, war, riot, civil commotion, malicious damage, compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery, fire, flood, storm or default of suppliers or sub-contractors, provided that the Customer is notified of such an event and its expected duration.`}</p>
    <h2>{`Variation`}</h2>
    <p>{`Subject to clause 5.3 (under Supply of the Training), no variation of the Agreement shall be effective unless it is in writing and signed by the parties (or their authorised representatives).`}</p>
    <h2>{`Waiver`}</h2>
    <p>{`No failure or delay by a party to exercise any right or remedy provided under the Agreement or by law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other right or remedy.`}</p>
    <h2>{`Severance`}</h2>
    <p>{`If any provision or part-provision of the Agreement is or becomes invalid, illegal or unenforceable, it shall be deemed deleted, but that shall not affect the validity and enforceability of the rest of the Agreement.`}</p>
    <h2>{`Entire Agreement`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The Agreement shall constitute the entire agreement between the parties and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between them, whether written or oral, relating to its subject matter.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Each party acknowledges that in entering into the Agreement it does not rely on, and shall have no remedies in respect of, any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in the Agreement.`}</p>
      </li>
    </ol>
    <h2>{`Assignment and Subcontracting`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The Customer shall not, without the prior written consent of the Provider, assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under the Agreement, such consent may be withheld in the Provider's sole discretion.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The Provider may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under the Agreement.`}</p>
      </li>
    </ol>
    <h2>{`No Partnership or Agency`}</h2>
    <p>{`Nothing in the Agreement is intended to or shall operate to create a partnership between the parties, or authorise either party to act as agent for the other, and neither party shall have the authority to act in the name or on behalf of or otherwise to bind the other in any way (including, but not limited to, the making of any representation or warranty, the assumption of any obligation or liability and the exercise of any right or power).`}</p>
    <h2>{`Third Party Rights`}</h2>
    <p>{`The Agreement does not confer any rights on any person or party (other than the parties to the Agreement and, where applicable, their successors and permitted assigns) pursuant to the Contracts (Rights of Third Parties) Act 1999.`}</p>
    <h2>{`Notices`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Any notice required to be given under the Agreement shall be in writing and shall be delivered by hand or sent by post or email to the other party at its address set out in the Agreement, or such other
address as may have been notified by that party for such purposes.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`A correctly addressed notice sent by pre-paid first-class post or recorded delivery post shall be deemed to have been received at the time at which it would have been delivered in the normal course of post. A notice sent email shall be deemed to have been received at the time of transmission (as shown by the time sent in respect of an email).`}</p>
      </li>
    </ol>
    <h2>{`Governing Law`}</h2>
    <p>{`The Agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales.`}</p>
    <h2>{`Jurisdiction`}</h2>
    <p>{`Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with the Agreement or its subject matter or formation (including non-contractual disputes or claims).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      